<template>
  <div class="pl-4 pr-4">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          <router-link
            :to="{ name: 'classDetail', params: { classId: $route.params.id } }"
          >
            {{ pageTitle }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item v-if="section === 'materials'">
          <router-link
            :to="{
              name: 'ClassMaterials',
              params: { classId: $route.params.id }
            }"
          >
            Materials
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          Edit
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <div>
      <MaterialsEdit
        v-if="section === 'materials'"
        :classId="$route.params.id"
      ></MaterialsEdit>
      <SessionClassForm
        v-else
        :sessionClass="sessionClass"
        @setSessionClass="setSessionClass"
        @onSubmit="onSubmit"
      />
    </div>
  </div>
</template>

<script>
import courseApi from "@/apis/course";
import formMixin from "@/mixins/form";
import Breadcrumb from "@/components/Breadcrumb";
import SessionClassForm from "@/views/sessionClasses/SessionClassForm";
import MaterialsEdit from "@/views/sessionClasses/materials/Edit.vue";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - Ivy-Way Academy`
    };
  },

  components: {
    Breadcrumb,
    SessionClassForm,
    MaterialsEdit
  },

  mixins: [formMixin],

  props: [],
  data() {
    return {
      sessionClass: {},
      originalTeachers: []
    };
  },
  computed: {
    pageTitle() {
      if (this.sessionClass.title) {
        return `${this.sessionClass.title}`;
      } else {
        return "";
      }
    },
    section() {
      return this.$route.query.section;
    }
  },
  async mounted() {
    let sessionClassRes = await courseApi.fetchSessionClass(
      this.$route.params.id,
      {
        test_type: "weekly"
      }
    );
    if (
      sessionClassRes.session_class.session_class_assign_tests &&
      sessionClassRes.session_class.session_class_assign_tests.length > 0
    ) {
      sessionClassRes.session_class.session_class_assign_tests = sessionClassRes.session_class.session_class_assign_tests.map(
        session_class_assign_test => {
          if (session_class_assign_test.exam === null) {
            return {
              ...session_class_assign_test,
              exam: { exam_category_id: -1 },
              is_created: 0
            };
          } else {
            if (
              session_class_assign_test.exam_type ===
              "App\\Entities\\SatMocks\\Exam"
            ) {
              //设置成“SAT线上模考”
              session_class_assign_test.exam["exam_category_id"] = -1;
            } else if (
              //设置成“TOEFL线上模考”
              session_class_assign_test.exam_type ===
              "App\\Entities\\TestAdmit\\Exam"
            ) {
              session_class_assign_test.exam["exam_category_id"] = 1;
            } else if (
              session_class_assign_test.exam_type === "App\\Entities\\Exam"
            ) {
              console.log(session_class_assign_test.exam.exam_category_id);
            } else {
              session_class_assign_test.exam["exam_category_id"] = -1;
            }

            if (
              session_class_assign_test.hide_answer &&
              session_class_assign_test.hide_exp
            ) {
              session_class_assign_test["hide_exp_answer"] = 1;
            } else {
              session_class_assign_test["hide_exp_answer"] = 0;
            }
            session_class_assign_test["is_created"] = 0;
            return session_class_assign_test;
          }
        }
      );
    }
    this.originalTeachers = sessionClassRes.session_class.session_class_teachers.filter(
      teacher => teacher.is_active
    );
    this.sessionClass = {
      ...sessionClassRes.session_class,
      teachers: this.originalTeachers.map(({ teacher_user_id }) => ({
        teacher_user_id
      }))
    };
  },

  methods: {
    setSessionClass(session_class_assign_tests) {
      if (
        (session_class_assign_tests.session_class_assign_tests?.length || 0) > 0
      ) {
        session_class_assign_tests.session_class_assign_tests.forEach(
          (item, index) => {
            item.which_week = index;
          }
        );
      }
      this.sessionClass = {
        ...this.sessionClass,
        ...session_class_assign_tests
      };
    },
    async onSubmit() {
      const getTeacherActionMode = teacher => {
        const teacherExisted = this.originalTeachers.some(
          ({ teacher_user_id }) => teacher_user_id === teacher.teacher_user_id
        );
        return teacherExisted ? "E" : "A";
      };
      const teachersAttachedActionMode = this.sessionClass.teachers.map(
        teacher => ({
          ...teacher,
          action_mode: getTeacherActionMode(teacher)
        })
      );

      const deletedTeachers = this.originalTeachers
        .filter(teacher =>
          this.sessionClass.teachers.every(
            ({ teacher_user_id }) => teacher_user_id !== teacher.teacher_user_id
          )
        )
        .map(teacher => ({ ...teacher, action_mode: "D" }));
      if (
        this.sessionClass.session_class_assign_tests &&
        this.sessionClass.session_class_assign_tests.length > 0
      ) {
        this.sessionClass.session_class_assign_tests.forEach((arr, index) => {
          if (arr.exam.exam_category_id == -1) {
            //SAT线上
            this.sessionClass.session_class_assign_tests[index]["exam_type"] =
              "App\\Entities\\SatMocks\\Exam";
          } else if (arr.exam.exam_category_id == 1) {
            //SAT线上
            this.sessionClass.session_class_assign_tests[index]["exam_type"] =
              "App\\Entities\\TestAdmit\\Exam";
          } else {
            //ivyway
            this.sessionClass.session_class_assign_tests[index]["exam_type"] =
              "App\\Entities\\Exam";
          }
        });
      }
      console.log(this.sessionClass.session_class_assign_tests);
      try {
        await courseApi.postSessionClass(this.sessionClass.id, {
          ...this.sessionClass,
          teachers: [...deletedTeachers, ...teachersAttachedActionMode]
        });
        this.$message({
          showClose: true,
          message: this.$t("message.update_success"),
          type: "success"
        });
        this.routerPush({
          name: "classDetail",
          params: {
            classId: this.$route.params.id
          }
        });
      } catch (e) {
        this.$message({
          showClose: true,
          message: this.$t("message.something_went_wrong"),
          type: "error"
        });
      }
    }
  }
};
</script>

<style scoped></style>
